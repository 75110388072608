function eventsInterface(definition) {
  const ifc = {};
  for (const [method, defn] of Object.entries(definition)) {
    ifc[method] = (params) => {
      const eventName = defn.name;
      const eventParams = pluckParams(params, defn.params);
      dispatch(eventName, eventParams);
    };
  }
  return ifc;
}

function dispatch(name, detail = {}) {
  const event = new CustomEvent(`PurpleDot:${name}`, { detail });
  window.dispatchEvent(event);
}

function pluckParams(params, definitionParams = []) {
  const result = {};
  for (const paramName of definitionParams) {
    result[paramName] = params[paramName];
  }
  return result;
}

export default eventsInterface({
  ready: {
    name: 'Ready',
  },
  // Placement events
  loaded: {
    name: 'PlacementLoaded',
    params: [
      'placementType',
      'instanceId',
      'skuId',
      'sku',
      'displayCurrency',
      'disabled',
      'releaseId',
    ],
  },
  failed: {
    name: 'PlacementFailed',
    params: ['instanceId', 'placementType', 'message', 'reason'],
  },
  learnMoreClicked: {
    name: 'LearnMoreClicked',
    params: [
      'placementType',
      'instanceId',
      'skuId',
      'sku',
      'displayCurrency',
      'releaseId',
    ],
  },
  buttonClicked: {
    name: 'ButtonClicked',
    params: ['instanceId', 'skuId', 'sku', 'displayCurrency', 'releaseId'],
  },
  addToCart: {
    name: 'AddToCart',
    params: ['skuId', 'internalSkuId', 'price', 'source'],
  },
  removeFromCart: {
    name: 'RemoveFromCart',
    params: [],
  },
  checkoutLoaded: {
    name: 'CheckoutLoaded',
    params: ['enableCombinedCart'],
  },
  // Pre-orders
  preorderCheckoutStep: {
    name: 'PreorderCheckoutStep',
    params: ['skuId', 'stepName', 'stepNumber', 'releaseId'],
  },
  preorderCheckoutSubmitted: {
    name: 'PreorderCheckoutSubmitted',
    params: ['skuId', 'releaseId'],
  },
  preorderCreated: {
    name: 'PreorderCreated',
    params: [
      'reference',
      'email',
      'shippingAddress',
      'lineItems',
      'total',
      'shipping',
      'tax',
      'discountCode',
    ],
  },
  orderCreated: {
    name: 'OrderCreated',
    params: [
      'skuId',
      'reference',
      'email',
      'shippingAddress',
      'lineItem',
      'lineItems',
      'total',
    ],
  },
  preorderFailed: {
    name: 'PreorderFailed',
    params: ['skuId', 'errorMessage'],
  },
  preorderCancelled: {
    name: 'PreorderCancelled',
    params: ['preorderReference'],
  },
  arrangeReturnClicked: {
    name: 'ArrangeReturnClicked',
    params: ['preorderReference'],
  },
  cartButtonVisibilityChanged: {
    name: 'CartButtonVisibilityChanged',
    params: ['showing'],
  },
});
