import {
  defineSelfServiceCustomElement,
  handleLegacyManagePreordersEl,
} from '@purple-dot/web-components/self-service/self-service-web-component';
import cookies from 'js-cookie';
import buttonImpressionHandler from '../button-impression-handler';
import Cart from './components/cart';
import Checkout from './components/checkout';
import LearnMore from './components/learn-more';
import NotifyMe from './components/notify-me';
import LoadRequest from './load-request';
import { ParentPageSDKCheckoutUpdates } from './parent-page-checkout-updates';
import CartButtonPositions from './placements/cart-button-positions';
import PlacementFactory from './placements/placement-factory';

import { CombinedCheckoutIframe } from '@purple-dot/web-components/checkout/combined-checkout-iframe';
import ShopifyBehaviour from '../platforms/shopify';

class EmbeddedSDK {
  constructor(
    apiKey,
    hostURL,
    staticComponents,
    placementFactory,
    publicEvents,
    flags
  ) {
    this.hostURL = hostURL;
    this.apiKey = apiKey;

    this.loadPreorderStatusPage = this.loadPreorderStatusPage.bind(this);

    this.placementFactory = placementFactory;
    this.placements = {};

    this.staticComponents = staticComponents;
    this.checkoutUpdates = new ParentPageSDKCheckoutUpdates(
      staticComponents,
      publicEvents,
      flags
    );
    this.checkoutUpdates.start();
    this.cartShown = false;

    staticComponents.checkout.attachListener({
      onCheckoutDismissed() {
        if (!this.cartShown && flags.closeCheckoutCartBehaviour !== 'none') {
          staticComponents.cart.show();
          this.cartShown = true;
        }
      },
    });

    staticComponents.cart.attachListener({
      cartCheckoutBtnClicked(d) {
        if (isOpenBoxSession()) {
          window.dispatchEvent(new Event('open-add-to-preorder-iframe'));
        } else {
          staticComponents.checkout.open(d);
        }
      },
    });

    staticComponents.cart.askForPositionFrom(
      new CartButtonPositions(this.placements)
    );
  }

  init({ shopifyCart, shopifyCartString, shopifyCartSignature, discountCode }) {
    this._handleCombinedCartBehaviour({
      shopifyCart,
      shopifyCartString,
      shopifyCartSignature,
      discountCode,
    });
  }

  load(requestData) {
    const request = new LoadRequest(this.placementFactory, requestData);
    const [container, placement] = request.value();
    if (!container) {
      return;
    }
    placement.mountInto(container);

    this.placements[request.placementId()] = placement;
  }

  loadPreorderStatusPage(args) {
    handleLegacyManagePreordersEl({
      apiKey: this.apiKey,
      ...args,
    });

    defineSelfServiceCustomElement(this.hostURL);
  }

  update(requestData) {
    const key = `${requestData.placementType}_${requestData.instanceId}`;
    const placement = this.placements[key];
    if (!placement) {
      return;
    }

    placement.update(requestData);
  }

  matchCartButtonToElement(options) {
    const key = `cart-button_${options.instanceId || '1'}`;
    const cartButton = this.placements[key];
    if (cartButton) {
      cartButton.matchCartButtonToElement(options);
    }
  }

  cartContainsPreorderItems({ shopifyCart }) {
    return (
      shopifyCart && ShopifyBehaviour.cartContainsPreorderItems({ shopifyCart })
    );
  }

  showCombinedCart({
    shopifyCart,
    shopifyCartString,
    shopifyCartSignature,
    discountCode,
  }) {
    onInteractive(() => {
      this._addLoadingOverlay();
      const shopifyCartId = cookies.get('cart');
      const goAffProRef = (document.cookie.match(/ref=([a-zA-Z0-9]+)/) ||
        [])[1];

      this.staticComponents.combinedCheckoutIframe.mount({
        id: shopifyCartId,
        goAffProRef,
        cartType: 'ajax',
        items: shopifyCart.items,
        code: discountCode,
        emitPublicEvents: false,
        shopifyCart,
        shopifyCartString,
        shopifyCartSignature,
      });
    });
    onCheckoutLoaded(() => {
      this._removeLoadingOverlays();
    });
  }

  openCheckout({ checkoutId, cartEnabled }) {
    this.staticComponents.checkout.open({ checkoutId, cartEnabled });
  }

  _handleCombinedCartBehaviour({
    shopifyCart,
    shopifyCartString,
    shopifyCartSignature,
    discountCode,
  }) {
    if (isCartPage() && this.cartContainsPreorderItems({ shopifyCart })) {
      this.showCombinedCart({
        shopifyCart,
        shopifyCartString,
        shopifyCartSignature,
        discountCode,
      });
    } else {
      this._removeLoadingOverlays();
    }
  }

  _addLoadingOverlay() {
    const overlay = document.createElement('div');
    overlay.id = 'cart-overlay';
    document.body.appendChild(overlay);

    overlay.style.position = 'fixed';
    overlay.style.top = '0px';
    overlay.style.left = '0px';
    overlay.style.right = '0px';
    overlay.style.bottom = '0px';
    overlay.style.width = '100vw';
    overlay.style.height = '100vh';
    overlay.style.background = 'white';
    overlay.style.display = 'block';
    overlay.style['z-index'] = '2147483647';

    this.overlay = overlay;
  }

  _removeLoadingOverlays() {
    const cartOverlays = document.querySelectorAll('#cart-overlay');

    cartOverlays.forEach((overlay) => {
      overlay.parentNode.removeChild(overlay);
    });
  }
}

function createEmbeddedSdk(
  hostURL,
  publicEvents,
  getCustomerData,
  trackingController,
  initOptions
) {
  const { apiKey, locale } = initOptions;
  const flags = {
    enableCartFlag: initOptions.enableCart,
    enableCombinedCart: initOptions.enableCombinedCart,
    enableNotifyFlag: initOptions.enableNotify,
    addItemCartBehaviour: initOptions.addItemCartBehaviour || 'default', // open_checkout|default
    closeCheckoutCartBehaviour:
      initOptions.closeCheckoutCartBehaviour || 'default', // none|default
    delaySession: true,
    salesChannel: initOptions.salesChannel,
    locale,
  };

  const checkout = new Checkout(
    hostURL,
    apiKey,
    publicEvents,
    getCustomerData,
    locale
  );
  const staticComponents = {
    checkout,
    cart: new Cart(hostURL, apiKey, locale),
    learnMore: new LearnMore(hostURL, apiKey, locale),
    notifyMe: new NotifyMe(hostURL, apiKey, locale),
    combinedCheckoutIframe: new CombinedCheckoutIframe(
      hostURL,
      apiKey,
      initOptions.currency,
      locale,
      getCustomerData
    ),
  };

  const placementFactory = new PlacementFactory(
    hostURL,
    apiKey,
    staticComponents,
    flags,
    publicEvents,
    (iframe) => buttonImpressionHandler(trackingController, iframe)
  );

  return new EmbeddedSDK(
    apiKey,
    hostURL,
    staticComponents,
    placementFactory,
    publicEvents,
    flags
  );
}

function isCartPage() {
  return (
    window.location.pathname.endsWith('/cart') ||
    window.location.pathname.endsWith('/cart/')
  );
}

function onInteractive(cb) {
  if (document.readyState === 'loading') {
    // eslint-disable-next-line no-inner-declarations
    function onReadyStateChange() {
      cb();
      document.removeEventListener('readystatechange', onReadyStateChange);
    }

    document.addEventListener('readystatechange', onReadyStateChange);
  } else {
    cb();
  }
}

function onCheckoutLoaded(cb) {
  const intervalId = setInterval(() => {
    const checkout = document.querySelector('#checkout-iframe');
    if (checkout) {
      cb();
      clearInterval(intervalId);
    }
  }, 50);
}

function isOpenBoxSession() {
  const openBoxSendTarget = window.sessionStorage.getItem('pd-add-to-preorder');

  return !!openBoxSendTarget;
}

export default createEmbeddedSdk;
